<template>
  <div>
    <requirement-search @filtered="requirements = $event"> </requirement-search>
    <v-switch
      id="btn-toogle-releases-groupBy"
      v-model="isGroupedByPatches"
      class="inline-block mt-0"
      :label="$t('labelGroup')"
      dark
    ></v-switch>
    <release-card
      v-for="releaseData in releases"
      :key="`r-${releaseData[0]}`"
      :release="releaseData[1][0].release"
      :requirements="releaseData[1]"
      :isGroupedByPatches="isGroupedByPatches"
    >
    </release-card>
  </div>
</template>

<script>
import ReleaseCard from "./ReleaseCard.vue";
import RequirementSearch from "./RequirementSearch.vue";

export default {
  components: { ReleaseCard, RequirementSearch },
  data: function() {
    return {
      requirements: [],
      isGroupedByPatches: false
    };
  },
  computed: {
    releases() {
      console.log(this.requirements);
      return this.getLinkedReleases(this.requirements);
    }
  },
  methods: {
    getLinkedReleases(requirements) {
      const releases = new Map();
      for (const requirement of requirements.filter(r => r.release)) {
        const groupBy = this.isGroupedByPatches
          ? requirement.release.name
          : requirement.release.name.substring(0, 7);
        const r = releases.get(groupBy) ?? [];
        r.push(requirement);
        releases.set(groupBy, r);
      }
      const sorted = [...releases].sort((a, b) => b[0].localeCompare(a[0]));
      return sorted;
    }
  },
  i18n: {
    messages: {
      en: { labelGroup: "Show patches" },
      fr: { labelGroup: "Voir les patchs" }
    }
  }
};
</script>
