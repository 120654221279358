<template>
  <div>
    <release-list></release-list>
  </div>
</template>

<script>
import ReleaseList from "../components/tracking/ReleaseList.vue";
export default {
  components: { ReleaseList }
};
</script>
