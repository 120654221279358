<template>
  <v-card class="my-3" :id="`releaseCard-${release.name}`">
    <v-card-title>
      <v-btn
        :id="`releaseCard-${release.name}-button`"
        color="dark"
        text
        @click.stop="show = !show"
      >
        <v-icon>
          {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
      <div>{{ label }}</div>
      <v-card-subtitle v-if="isGroupedByPatches" class="font-italic">
        {{ releaseDate }}
      </v-card-subtitle>
    </v-card-title>
    <v-expand-transition>
      <div v-show="show">
        <v-card-text>
          <v-alert
            v-for="requirement in requirements"
            :key="`release-requirement-${requirement.number}`"
            text
            outlined
            dense
            color="info"
          >
            #{{ requirement.number }} - {{ requirement.title }}
          </v-alert>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: ["release", "requirements", "isGroupedByPatches"],
  data() {
    return {
      show: false
    };
  },
  computed: {
    releaseDate() {
      return this.$d(new Date(this.release.date));
    },
    label() {
      return this.isGroupedByPatches
        ? this.release.name
        : this.release.name.substring(0, 7);
    }
  }
};
</script>
